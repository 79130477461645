<template>
  <div>
    <oReturnPage
      v-if="!$route.meta.footer && $route.meta.returnPage"
    ></oReturnPage>
    <keep-alive v-if="$route.meta.keepAlive">
      <router-view :barheight.sync="tabberHeight"> </router-view>
    </keep-alive>
    <router-view v-else :barheight.sync="tabberHeight"> </router-view>
    <van-tabbar
      ref="tabber"
      v-if="$route.meta.footer"
      v-model="active"
      :before-change="css"
      route
      fixed
      safe-area-inset-bottom
    >
      <van-tabbar-item to="/home/index" name="HomeIndex">
        <span>首页</span>
        <template #icon="props">
          <img :src="props.active ? icon.home.active : icon.home.inactive" />
        </template>
      </van-tabbar-item>
      <van-tabbar-item to="/baby/index" name="Baby">
        <span>全部宝贝</span>
        <template #icon="props">
          <img :src="props.active ? icon.baby.active : icon.baby.inactive" />
        </template>
      </van-tabbar-item>
      <van-tabbar-item to="/list/index" name="List">
        <span>产品分类</span>
        <template #icon="props">
          <img :src="props.active ? icon.list.active : icon.list.inactive" />
        </template>
      </van-tabbar-item>
      <van-tabbar-item
        v-if="$store.state.cart.cart_num == 0"
        to="/cart/index"
        name="Cart"
      >
        <span>购物车</span>
        <template #icon="props">
          <img :src="props.active ? icon.cart.active : icon.cart.inactive" />
        </template>
      </van-tabbar-item>
      <van-tabbar-item
        :badge="$store.state.cart.cart_num"
        to="/cart/index"
        name="Cart"
        v-else
      >
        <span>购物车</span>
        <template #icon="props">
          <img :src="props.active ? icon.cart.active : icon.cart.inactive" />
        </template>
      </van-tabbar-item>
      <van-tabbar-item to="/me/index" name="Me">
        <span>我的</span>
        <template #icon="props">
          <img :src="props.active ? icon.me.active : icon.me.inactive" />
        </template>
      </van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script>
import { Tabbar, TabbarItem } from "vant";
import oReturnPage from "./return";
export default {
  data() {
    return {
      tabberHeight: 0,
      cart_badge: 0,
      active: "HomeIndex",
      css: async (act) => {
        let setup = await this.$store.getters;

        if (setup.getSetupLogin) {
          return false;
        } else {
          return true;
        }
      },
      icon: {
        home: {
          name: "首页",
          active: require("@image/tabbar/index_act.png"),
          inactive: require("@image/tabbar/index.png"),
        },
        baby: {
          name: "全部宝贝",
          active: require("@image/tabbar/baby_act.png"),
          inactive: require("@image/tabbar/baby.png"),
        },
        list: {
          name: "产品分类",
          active: require("@image/tabbar/list_act.png"),
          inactive: require("@image/tabbar/list.png"),
        },
        cart: {
          name: "购物车",
          active: require("@image/tabbar/cart_act.png"),
          inactive: require("@image/tabbar/cart.png"),
        },
        me: {
          name: "我的",
          active: require("@image/tabbar/me_act.png"),
          inactive: require("@image/tabbar/me.png"),
        },
      },
    };
  },

  methods: {
    async getCartNum() {
      this.$store.dispatch("doSetCartNum", "carnum");
    },
    onChange(active) {
      return false;
    },
  },
  mounted() {
    this.getCartNum();
    // console.log();
    // this.tabberHeight = this.$refs.tabber.$el.offsetHeight;
  },
  components: {
    vanTabbar: Tabbar,
    vanTabbarItem: TabbarItem,
    oReturnPage,
  },
};
</script>

<style lang="scss" scoped>
.van-tabbar--fixed {
  position: fixed !important;
  z-index: 999;
}
</style>
