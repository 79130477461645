<template>
  <div id="returnPage" @click="fooReturn">
    <svg
      t="1625047727820"
      class="icon"
      viewBox="0 0 1024 1024"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      p-id="3178"
      width="16"
      height="16"
    >
      <path
        d="M652.209231 986.190769l-472.615385-474.190769 472.615385-474.190769 34.658461 34.658461-441.107692 439.532308 441.107692 439.532308z"
        p-id="3179"
      ></path>
    </svg>
  </div>
</template>

<script>
export default {
  methods: {
    fooReturn() {
      try {
        if (window.history.length <= 1) {
          this.$router.push({ name: "HomeIndex" });
          return false;
        } else {
          this.$router.go(-1);
        }
      } catch (error) {
        this.$router.push({ name: "HomeIndex" });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@css/mixin.scss";
#returnPage {
  width: rem(50);
  height: rem(50);
  border-radius: 50%;
  background: #fff;
  position: fixed;
  top: rem(50);
  left: rem(50);
  z-index: 999;
  box-shadow: rem(2) rem(2) rem(14) rgba(0, 0, 0, 0.1);
  svg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
  }
}
</style>
